@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --red-primary: #ef5241;
    --red-darker: #c52514;
    --orange-primary: #fdaa56;
    --orange-secondary: #f4b474;
    --orange-light: #f4b474;
    --red-light: #f4977c;
    --white-primary: #fffaeb;
    --white-full: #fff;
    --black-full: #000;
}

body {
    background-color: #fffaeb;
    background-color: var(--white-primary);
    font-family: 'Economica', sans-serif;
}
.text-accent{
    color:  #ef5241;
    color:  var(--red-primary);
    transition: .5s ease-in-out;
}
.text-accent:hover{
    color:  #c52514;
    color:  var(--red-darker);
}
.text-o-primary{
    color:  #fdaa56;
    color:  var(--orange-primary);
    transition: .5s ease-in-out;
}
.text-o-primary:hover{
    color:  #fdaa56;
    color:  var(--orange-primary);
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;  
}
.no-gutters > .col,
.no-gutters  > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
}
.modal-content{
    background-color: #f4b474;
    background-color: var(--orange-secondary);
    color: #fff;
}
#navbar { 
    background-color: #fffaeb; 
    background-color: var(--white-primary);
    color: #fff;
    color: var(--white-full);
}
.navbar-nav{
    background-color: #fdaa56;
    background-color: var(--orange-primary);
    border-radius: 10px;
}
.navbar-collapse.collapse.show {
    margin-bottom: 20px;
}
.dropdown-menu{
    border-radius: 10px;
}
.dropdown-item{
    margin: 5px 0;
}
.dropdown-item:focus, .dropdown-item:hover {
    color: #fdaa56;
    color: var(--orange-primary);
    background-color: #fffaeb;
    background-color: var(--white-primary);
    border-radius: 7px;

}
.hero {
    background: #fffaeb;
    background: var(--white-primary);
    position: relative;
    height: 100vh;
}

.navbar-brand {
    font-size: 36px;
    font-weight: bold;
    color: #fff !important;
    color: var(--white-full) !important;
    padding: 0 !important;
}

.navbar-light .navbar-nav .nav-link {
    font-size: 25px;
    color: #fff;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
    color: #ef5241;
    color: var(--red-primary);
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    color: #fff;
    background-color: #ef5241;
    background-color: var(--red-primary);
}

.nav-link, .nav-link:focus, .nav-link:hover{
    color: #ef5241;
    color: var(--red-primary);
}

.dropdown-xl .dropdown-menu {
    background: #fdaa56;
    background: var(--orange-primary);
}

.hero .slant {
    position: absolute;
    width: calc(100% + 2px);
    bottom: -200px;
    height: 200px;
    left: 0px;
    z-index: -1;
    background-image: url(/static/media/Volugesim.87abb19d.svg);
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
}
.btn-check:focus+.btn-primary, .btn-primary:focus{
    box-shadow: none;
}
.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle{
    background-color: #ef5241;
    background-color: var(--red-primary);
    border: 1px solid #ef5241;
    border: 1px solid var(--red-primary);
}

.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus{
    box-shadow: none;
}

a.btn-primary,
button.btn-primary {
    font-size: 25px;
    padding: 0.5rem 1rem;
    background-color: #ef5241;
    background-color: var(--red-primary);
    border: none;
}

a.btn-primary:hover,
button.btn-primary:hover {
    background-color: #c52514;
    background-color: var(--red-darker);
    border: none;
}

a.dropdown-item {
    color: #fff;
    color: var(--white-full);
}

.hero-caption {
    padding-left: 10rem;
}

.hero-caption h1,
.hero-caption p,
.hero-caption a {
    font-family: 'Economica', sans-serif;
}

.hero-caption h1 {
    font-size: 68px;
    font-weight: bold;
}

.hero-caption p {
    font-weight: 200;
    color: #fdaa56 !important;
    color: var(--orange-primary) !important;
}
.hero-caption a{
    font-weight: 200;
    color: #fffaeb !important;
    color: var(--white-primary) !important;
}

.hero-caption p {
    font-style: italic;
    font-size: 35px;
}

.hero-caption a {
    font-size: 25px;
    background-color: #ef5241;
    background-color: var(--red-primary);
    border: none;
    padding: 0.7rem 2rem !important;
}

.hero-caption a:hover {
    background-color: #c52514;
    background-color: var(--red-darker);
    border: none;
}

.hero-caption {
    margin-top: 150px;
}
.bg-red-light{
    background-color: #fdaa56 !important;
    background-color: var(--orange-primary) !important;
}
.img-side img {
    height: calc(98vh);
    width: 120%;
}
.bg-red-light-shape{
    background-color: #fffaeb;
    background-color: var(--white-primary);

}

@media (min-width: 768px) and (max-width: 992px) {
    .hero-caption h1 {
        font-size: 50px;
    }

    .hero-caption p {
        font-size: 28px;
    }

    .hero-caption a {
        font-size: 20px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .hero-caption {
        padding-left: 5rem;
    }

    .hero-caption h1 {
        font-size: 50px;
    }
}

@media (max-width: 575px) {
    .hero-caption {
        padding-left: 3rem;
    }

    .hero-caption h1 {
        font-size: 40px;
    }

    .hero-caption p {
        font-size: 20px;
    }

    .hero-caption a {
        font-size: 15px;
    }
}

.card {
    background-color: #f4b474;
    background-color: var(--orange-secondary);
    border-radius: 16px;
    border: none;
}

.card .card-header {
    font-size: 24px;
    color: #fff !important;
    color: var(--white-full) !important;
    font-weight: 700;
    background-color: transparent;
    border-bottom: none;
}

.custom-file-input {
    color: transparent;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Choose Image';
    color: #fffaeb;
    color: var(--white-primary);
    display: inline-block;
    background: #ef5241;
    background: var(--red-primary);
    border: none;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-size: 15px;
}

.custom-file-input:hover::before {
    border-color: black;
}

.custom-file-input:active {
    outline: 0;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.icon i, .icon a {
    color: #ef5241;
    color: var(--red-primary);
    font-size: 25px;
    margin: 0 3px;
    transition: .2s ease-in-out;
}

.icon i:hover, .icon a:hover{
    cursor: pointer;
    font-size: 30px;
}

#status {
    color: #fff;
}

div.image {
    margin-top: 10px;
}

select.form-control {
    background-color: #ef5241;
    background-color: var(--red-primary);
    border: none;
    color: #fffaeb;
    color: var(--white-primary);
}

select.form-control:focus {
    color: #fffaeb;
    color: var(--white-primary);
    background-color: #ef5241;
    background-color: var(--red-primary);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(0 97 242 / 25%);
}

.form-group label {
    font-size: 20px;
    color: #fff !important;
    color: var(--white-full) !important;
    font-weight: 700;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: #ef5241;
    background: var(--red-primary);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    background: #ef5241;
    background: var(--red-primary);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.kernel {
    background-color: #ef5241;
    background-color: var(--red-primary);
    padding: 3px 7px;
    color: #fff;
    color: var(--white-full);
}
.kernel-text{
    font-size: 20px;
    color: #fff !important;
    color: var(--white-full) !important;
    font-weight: 700;
    margin-top: 15px;
}
.btn-submit {
    font-size: 18px !important;
}

.input-wrap{
    max-width: 50px;
}
.input-wrap input{
    padding: .2rem .2rem;
}
.row-custom{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);

}
.row-custom > *{
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    margin-left: 5px;
}
@-webkit-keyframes move-right {
    100% { -webkit-transform: translate(0.5em, 0); }
}
@keyframes move-right {
    100% { -webkit-transform: translate(0.5em, 0); transform: translate(0.5em, 0); }
}
ul.reset-style {
    list-style: none;
}
ul.custom-style-base > li{
    font-size: 20px;   
}
ul.custom-style-base > li:hover > a{
    color: #ef5241;
    color: var(--red-primary);
}
ul.custom-style-base > li > a{
    text-decoration: none;
    color: #fff;
    color: var(--white-full);
    transition: .2s ease-in-out ;
}
ul.custom-style-base li:before {
    position: absolute;
    margin-left: -1.5em;
    padding-right: 1em;
    color: rgba(255,0,0,1);
    content: '\25CF';
}
ul.custom-style-hover-color li:hover:before {
    color: rgba(255,0,0,0.5);
}
ul.custom-style-hover-type-arrow li:hover:before {
    content: '\21D2';
}
ul.custom-style-hover-animate li:hover:before {
    -webkit-animation: move-right 0.5s 0s 1 ease forwards;
    animation: move-right 0.5s 0s 1 ease forwards;
}
.text-title{
    font-family: 'Poppins', sans-serif !important;
}
.error-container {
    text-align: center;
    font-size: 106px;
    font-family: 'Catamaran', sans-serif;
    font-weight: 800;
    margin: 70px 15px;
}

.error-container>span {
    display: inline-block;
    position: relative;
}

.error-container>span.four {
    width: 136px;
    height: 43px;
    border-radius: 999px;
    background:
        linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
        linear-gradient(105deg, transparent 0%, transparent 40%, rgba(0, 0, 0, 0.06) 41%, rgba(0, 0, 0, 0.07) 76%, transparent 77%, transparent 100%),
        linear-gradient(to right, #d89ca4, #e27b7e);
}

.error-container>span.four:before,
.error-container>span.four:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 999px;
}

.error-container>span.four:before {
    width: 43px;
    height: 156px;
    left: 60px;
    bottom: -43px;
    background:
        linear-gradient(128deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 40%, transparent 41%, transparent 100%),
        linear-gradient(116deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 50%, transparent 51%, transparent 100%),
        linear-gradient(to top, #99749D, #B895AB, #CC9AA6, #D7969E, #E0787F);
}

.error-container>span.four:after {
    width: 137px;
    height: 43px;
    -webkit-transform: rotate(-49.5deg);
            transform: rotate(-49.5deg);
    left: -18px;
    bottom: 36px;
    background: linear-gradient(to right, #99749D, #B895AB, #CC9AA6, #D7969E, #E0787F);
}

.error-container>span.zero {
    vertical-align: text-top;
    width: 156px;
    height: 156px;
    border-radius: 999px;
    background: linear-gradient(-45deg, transparent 0%, rgba(0, 0, 0, 0.06) 50%, transparent 51%, transparent 100%),
        linear-gradient(to top right, #99749D, #99749D, #B895AB, #CC9AA6, #D7969E, #ED8687, #ED8687);
    overflow: hidden;
    -webkit-animation: bgshadow 5s infinite;
            animation: bgshadow 5s infinite;
}

.error-container>span.zero:before {
    content: '';
    display: block;
    position: absolute;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    width: 90px;
    height: 90px;
    background-color: transparent;
    left: 0px;
    bottom: 0px;
    background:
        linear-gradient(95deg, transparent 0%, transparent 8%, rgba(0, 0, 0, 0.07) 9%, transparent 50%, transparent 100%),
        linear-gradient(85deg, transparent 0%, transparent 19%, rgba(0, 0, 0, 0.05) 20%, rgba(0, 0, 0, 0.07) 91%, transparent 92%, transparent 100%);
}

.error-container>span.zero:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 999px;
    width: 70px;
    height: 70px;
    left: 43px;
    bottom: 43px;
    background: #FDFAF5;
    box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}

@-webkit-keyframes bgshadow {
    0% {
        box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
    }

    45% {
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    55% {
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    100% {
        box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
    }
}

@keyframes bgshadow {
    0% {
        box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
    }

    45% {
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    55% {
        box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    }

    100% {
        box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
    }
}

/* demo stuff */
* {
    box-sizing: border-box;
}

body {
    background-color: #FDFAF5;
    margin-bottom: 50px;
}

html,
button,
input,
select,
textarea {
    font-family: 'Montserrat', Helvetica, sans-serif;
    color: #bbb;
}

h1 {
    text-align: center;
    margin: 30px 15px;
}

.zoom-area {
    max-width: 490px;
    margin: 30px auto 30px;
    font-size: 19px;
    text-align: center;
}

.link-container {
    text-align: center;
}

a.more-link {
    text-transform: uppercase;
    font-size: 13px;
    background-color: #de7e85;
    padding: 10px 15px;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
    margin-top: 50px;
    letter-spacing: 1px;
}
